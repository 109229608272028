import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import FragrancesList from '../../components/fragrances-list'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Paragraph from '../../components/paragraph'
import SEO from '../../components/seo'


export const frontmatter = {
  body: 'On Sale Perfumes',
  slug: '/perfumes/sale/',
  title: 'On Sale Perfumes',
}

export const query = graphql`
  query SalePageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    results: postgres {
      fragrances: viewFragrancesSaleList(
        filter: { gender: { equalTo: $genderId }}
      ) {
        ...Fragrance
        brand: brandByBrand {
          ...Brand
        }
      }
    }
  }
`

export default ({ data }) => {
  const { fragrances } = data.results
  const { links, meta } = data.site.siteMetadata
  const { entity, genderization } = meta

  const page = 'Sale'
  const title = `On ${page} & Discount ${entity.plural}`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: entity.plural,
      slug: links.fragrances,
    },
    {
      name: page,
      slug: links.sale,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    products: fragrances,
    slug: links.sale,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Paragraph>
        These are the most discounted and affordable {genderization} fragrances
        on our site.
      </Paragraph>

      <FragrancesList fragrances={fragrances} />
      <Breadcrumb trail={trail} />
    </Layout>
  )
}
